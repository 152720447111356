:root {
  --prime-color: #6e46c9;
  --second-color: #4ba1dc;
}

aside {
  max-width: 250px;
  height: 100vh;
  background: #fff;
}

.super-left-sidebar {
  padding: 20px 10px;
  padding-bottom: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  bottom: 0px;
  top: 0;
  left: 0;
  z-index: 5;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
aside .logo {
  margin-bottom: 1rem;
}
.super-left-sidebar menu {
  margin-top: 1rem;
  width: 100%;
  padding: 0 15px;
}
.super-left-sidebar menu ul {
  list-style: none;
  margin: 2rem 0;
}
.dropdown-toggle::after {
  right: -30px;
}
menu ul li {
  margin: 1.5rem 0;
}

menu ul li a {
  text-decoration: none;
  color: var(--second-color);
}
menu ul li a:hover {
  text-decoration: none;
}
menu ul li a span {
  color: gray;
  font-size: 14px;
}

.super-sidebar-bottom {
  text-align: center;
}
.super-sidebar-bottom img {
  width: 110px;
}

aside.super-left-sidebar.unactive {
  left: -100%;
  display: none;
}

.super-list-unstyled li {
  display: flex;
  align-items: center;
}
.super-list-unstyled li span:hover {
  text-decoration: underline;
}

.owlimg {
  text-align: center !important;
}
/* End Super Sidebar */
/* Super Header Code */
.super-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* End Super Header Code */
/* Super Card */
.super-dashbard-inner-card {
    padding: 20px;
    margin-top: -6rem;
  }
  .super-dashbard-inner-card .super-card-inner {
    background: #fff;
    border-radius: 30px;
    text-align: center;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .super-dashbard-inner-card .super-middle-text {
    font-size: 20px;
    font-weight: 600;
    color: #404040;
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
  .super-dashbard-inner-card .super-middle-text span {
    font-size: 37px;
    font-weight: 700;
  }

@media (max-width: 767px) {
  .super-left-sidebar {
    display: block;
    position: fixed;
    width: 50%;
    left: -50%;
    z-index: 999;
  }
  aside.super-left-sidebar.active {
    left: 0%;
    display: flex;
    margin-bottom: 50px;
    height: 100%;
  }
  .aside.super-left-sidebar.active .super-sidebar-bottom {
    padding-bottom: 50px !important;
  }
  .super-sidebar-bottom {
    text-align: center;
    margin-bottom: 24px;
  }

  .super-top-bar {
    display: block;
  }

  .super-dashbard-inner-card {
    margin-top: 0;
  }
  .super-dashbard-inner-card .super-card-inner {
    margin-bottom: 1rem;
  }

}

.sales-register-create {
    position: static !important;
    margin: auto;
    transform: inherit;
    margin-top: 50px !important;
  }
  
  .table-data-wrapper {
    border-radius: 25px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: 5px 20px;
    height: 400px;
    overflow-y: auto;
  }
  .table-inner-content .table-responsive {
    box-shadow: 0 7px 29px 0 hsla(240, 5%, 41%, 0.2);
  }
  #res-table-layout{
    width: 95%;
    min-width: 95%;
    margin: 0.5 1rem;
    color: "gray";
  }
  button.button-30-add-sales {
    background-image: linear-gradient(#66C5C9, #01CFC2);
    padding: 10px;
    color: #fff;
    font-size: 13px;
    margin-bottom: 10px;
    border: none;
    padding: 5px 10px;
  }
  
  .buttom-parent-div-sales {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 20px;
  }
  
  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .bg-img-page-register{
      background-image: url("/public/img/BG-Desktop.jpg");
      background-size: cover;
      width: 95%;
  }
  
  @media (max-width: 767px) {
    .sales-register-create {
      margin: 1.3rem;
    }
    .table-data-wrapper {
      height: auto;
      border: 2px solid #3498ff17;
      margin: 0.5rem;
    }
    .sales-register-create input {
      background-color: #3498ff17;
    }
    .sales-register-create h5 {
      margin-bottom: 1rem;
    }
  }
  
