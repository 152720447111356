.google-reviews-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.google-reviews-heading {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.average-rating-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.average-rating {
  font-size: 2rem;
  font-weight: bold;
  color: #6c5ce7;
  margin: 0.6rem;
}

.rating-icons {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.rating-star {
  color: #ccc;
}

.rating-star.filled {
  color: #f8c941;
}

.total-reviews {
  font-size: 1.2rem;
  color: #666;
  margin-left: 10px;
}

.reviews-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 15px;
}

.review-card {
  width: 250px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.review-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.review-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.review-author-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.review-author-info {
  display: flex;
  flex-direction: column;
}

.review-text {
  font-size: 0.9rem;
  color: #333;
  margin: 10px 0;
}

.review-rating {
  display: flex;
  align-items: center;
}

.review-author,
.review-time {
  font-size: 1rem;
  color: #666;
}

.loading-spinner {
  display: block;
  margin: 50px auto;
}

@media (max-width: 768px) {
  .google-reviews-container {
    padding: 10px;
  }

  .average-rating {
    font-size: 1.5rem;
  }

  .review-card {
    width: 100%;
    margin-bottom: 15px;
  }
}