.privacy-policy, .cookies-policy, .terms-and-conditions {
  width: 85%;
  margin: 0 auto;
  padding: 1rem;
  font-family: Arial, sans-serif;
}

.privacy-policy div, .cookies-policy .cookies-sub-headings, .terms-and-conditions .terms-sub-headings{
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.privacy-policy .policy-heading, .cookies-policy .cookies-headings, .terms-and-conditions .terms-headings{
  font-size: 1.7rem;
  font-weight: 600;
}

.privacy-policy p {
  margin-bottom: 0.5rem;
}

.privacy-policy ul {
  list-style-type: disc;
  margin-left: 2rem;
}

.privacy-policy a {
  color: #007bff;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}

.cookies-policy p{
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.terms-and-conditions p{
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
