*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.bg-img-page {
  background-image: url("/public/img/BG-Desktop.jpg");
  background-size: cover;
  width: 100%;
  background-size: cover;
  height: 100vh;
}
.login-wrapper {
  width: 430px;
  height: 520px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.login-wrapper .shape {
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
}
.logo-header {
  text-align: center;
  margin-top: -59px;
}
.shape:first-child {
  left: -80px;
  top: -80px;
}
form {
  height: auto;
  width: 360px;
  background-color: #f3f3f3;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 18px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  padding: 20px 35px;
}
form * {
  letter-spacing: 0.5px;
  outline: none;
  border: none;
}
form h3 {
  font-size: 32px;
  font-weight: bold;
  line-height: 42px;
  text-align: center;
  color: #6442c0 !important;
}
input[type="checkbox"] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  appearance: checkbox;
  display: inline-block;
  width: auto;
}

form h3 span {
  display: block;
  font-size: 16px;
  font-weight: 300;
  color: #000 !important;
}

input,
select,
textarea {
  display: block;
  height: auto;
  width: 100%;
  margin-top: 10px !important;
  border-radius: 27px;

  color: black;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  padding: 10px;
  font-weight: 300;
}

.password-input-wrapper {
  position: relative;
}
.admin-login-pass-icon {
  position: absolute;
  right: -1rem;

  color: #000000;
  top: 1.4rem;
}
::placeholder {
  color: #000;
}
.headingWelcomeBack {
  color: #9755d0;
}
button#sidebarCollapse {
  position: relative;
  left: -40px;
}
.login-button-admin,
.forgot-submit-btns {
  margin-top: 20px;
  width: 44%;
  color: #fff !important;
  outline: none;
  background: rgb(0, 141, 204);
  background: linear-gradient(
    90deg,
    rgba(0, 141, 204, 1) 2%,
    rgba(102, 21, 172, 0.751313025210084) 53%
  );
  color: #0b4e86;
  padding: 5px 0;
  font-size: 16px;
  font-weight: 600;
  border-radius: 27px;
  cursor: pointer;
}

.dashboard-charts-wrapper {
  margin-left: 20px;
  margin-right: 20px;
}
@media (max-width: 767px) {
  .login-wrapper {
    position: static;
  }
  form {
    width: 90%;
    height: auto;
    margin-top: 0 !important;
  }
  .sales-register-create {
    margin-top: 0 !important;
  }
  .customer-login .table-data-wrapper-z123 {
    height: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.bg {
  width: 100%;
  height: 150px;
  background: #fff;
  position: absolute;
  top: 0em;
  left: 0;
  right: 0;
  margin: auto;
  background-position: center;
  z-index: -999;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-clip-path: ellipse(69% 46% at 50% 50%);
  clip-path: ellipse(56% 135% at 48% 46%);
  border-bottom-left-radius: 247px;
  border-bottom-right-radius: 241px;
  border-top-left-radius: 21px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-top-right-radius: 21px;
}
.login-side-img {
  position: relative;
  left: -42px;
  top: 10px;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.css-3su884-MuiPaper-root {
  background-color: none !important;
  box-shadow: none !important;
}
.css-3su884-MuiPaper-root {
  background-color: #f7f7f7 !important;
}

.link-right-wrapper {
  text-align: end;
  margin: 0.3rem 0;
}

.login-button-admin {
  margin: auto;
  display: block;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
