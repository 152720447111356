.not-head > header {
  height: 5.5rem;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 1rem;
}

.not-card {
  border: 2px solid #5585d6;
  border-radius: 1rem;
  transition: all 0.3s ease;
  cursor: pointer;
  animation: fadeIn 0.5s ease-in-out forwards;
  opacity: 0;
}

.not-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.reminder-card-logo {
  width: 5rem;
  height: 5rem;
  margin-right: 1rem;
}

.unseen {
  background-color: #d6eaf8;
}

.seen {
  background-color: #ffffff;
}

.notification-content {
  width: 80%;
}

.notification-description {
  font-size: 1.2rem;
  font-weight: bold;
}

.notification-datetime,
.notification-customer,
.notification-created,
.notification-seen {
  font-size: 1rem;
  margin-top: 0.5rem;
}

.reminder-btn-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
}

.delete-btn {
  margin-right: 1rem;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .cards-container {
    grid-template-columns: 1fr;
  }

  .not-head > header {
    height: 8.5rem;
  }

  .notification-content {
    width: 65%;
  }
}

.empty-state {
  text-align: center;
  padding: 2rem;
}

.empty-state img {
  width: 200px;
  margin-bottom: 1rem;
}
