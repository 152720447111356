:root {
  --prime-color: #6e46c9;
  --second-color: #63b7f0;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  );
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.bg-img-card-wrapper {
  color: #000000;
  background-image: linear-gradient(180deg, var(--second-color),var(--prime-color))
}
