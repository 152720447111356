@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  margin: 0;
  padding: 0;
  font-family: "Rubik", sans-serif;
}

menu ul li a {
  text-decoration: none;
}

.h-70vh {
  height: 70vh;
}

.checkboxText {
  text-align: center;
}

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 backwards !important;
}

.g-1 {
  gap: 1rem;
}

.bg-color-delete {
  color: red;
  font-size: 15px;
}
.bg-color-edit {
  color: #4ba3dd;
  font-size: 15px;
}
