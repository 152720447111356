.parent-div-progressbar {
  padding: 20px;
}

.color-pilot-name-customerPC .dot {
  height: 12px;
  width: 12px;
  background-color: #db3b6b;

  display: inline-block;
}
.parent-color-pillet-perform {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.progress,
.progress-stacked {
  --bs-progress-bg: var(--bs-progress-bg) !important;
}
.progress-bar-class-one {
  background-color: #6b71db;
}
.progress-bar-class-two {
  background-color: #db3b6b;
}
form#sales-login-width {
  margin-top: 102px !important;
}
:root {
  --prime-color: #6e46c9;
  --second-color: #4ba1dc;
}
body {
  background: #f0f1f7;
}
.main-dashboard {
  display: flex;
}

aside {
  max-width: 250px;
  height: 100vh;
  background: #fff;
}
.left-sidebar {
  padding: 20px 10px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  bottom: 0px;
}
aside .logo {
  margin-bottom: 1rem;
}
.left-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.left-sidebar menu {
  margin-top: 1rem;
  width: 100%;
  padding: 0 15px;
}
.left-sidebar menu ul {
  list-style: none;
  margin: 2rem 0;
}
.dropdown-toggle::after {
  right: -30px;
}
menu ul li {
  margin: 1.5rem 0;
}
menu ul li a {
  text-decoration: none;
  color: var(--second-color);
}
menu ul li a:hover {
  text-decoration: none;
}
menu ul li a span {
  color: gray;
  font-size: 14px;
}

.sidebar-bottom {
  text-align: center;
}
.sidebar-bottom img {
  width: 110px;
}
.main-btn {
  background: linear-gradient(90deg, var(--prime-color), var(--second-color));
  color: #fff;
  width: 100%;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.Dashbaord-wrapper-margin-left {
  padding-left: 250px;
}
.Dashboard-wrapper {
  position: relative;
  width: 100%;
}
.Dashboard-wrapper.active {
  padding-left: 0;
}
aside.left-sidebar.active {
  left: -100%;
  display: none;
}
.Dashboard-wrapper header {
  padding: 1rem 1.5rem;
  height: 9.5rem;
  background: linear-gradient(180deg, var(--prime-color), var(--second-color));
  color: #fff;
}
.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-unstyled li {
  display: flex;
  align-items: center;
}
.list-unstyled li span:hover {
  text-decoration: underline;
}
.Dashboard-wrapper header .top-right {
  display: flex;
  gap: 1.5rem;
}
.Dashboard-wrapper header .date-picker-wrap {
  padding: 10px 15px;
  border-radius: 30px;
  color: gray;
  font-size: 14px;
  border-right: 1px solid gray;
  position: relative;
}
.Dashboard-wrapper header .date-picker-wrap:after {
  content: "";
  position: absolute;
  top: 0;
  right: -10px;
  width: 1px;
  height: 100%;
}
.dashbard-inner-card {
  padding: 20px;
  margin-top: -6rem;
}
.dashbard-inner-card .card-inner {
  background: #fff;
  border-radius: 30px;
  text-align: center;
  padding: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.top-content h2 {
  font-size: 15px;
  color: #a6a6a6;
  height: 30px;
}
.dashbard-inner-card .middle-text {
  font-size: 20px;
  font-weight: 600;
  color: #404040;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.dashbard-inner-card .middle-text span {
  font-size: 37px;
  font-weight: 700;
}

.placement-bottom-start {
  left: 792px !important;
  top: 66px !important;
}

.dashboard-charts-wrapper {
  padding-left: 15px;
  width: auto;
  margin: 0 20px;
  border-radius: 25px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: 100%;
}
.dashboard-charts-wrapper.h-auto {
  height: auto;
}
.recharts-wrapper {
  position: relative;
  top: -30px;
  cursor: default;
  width: 268px;
}
.main-simpleline-chart .recharts-wrapper {
  top: 10px;
}

.dashbard-inner-card .per-count {
  color: #08cf53;
}
.cart-wrapper #myChart {
  width: 100% !important;
  margin: auto;
  height: 250px !important;
}

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 767px) {
  .left-sidebar {
    display: block;
    position: fixed;
    width: 50%;
    left: -50%;
    z-index: 999;
  }
  .logout-text-mobile {
    display: none;
  }
  #top-left {
    flex-direction: row-reverse;
    justify-content: space-between !important;
  }
  .Dashboard-wrapper header .top-right {
    display: flex;
    justify-content: space-between;
    margin: 0.6rem 0;
  }
  .css-4vs8q7-MuiResponsiveChart-container > svg {
    margin-right: 69px !important;
  }
  .avatar-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .rs-stack {
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .left-sidebar menu {
    padding: 0px;
  }
  #Dashbaord-wrapper-margin-left {
    padding-left: 0px;
  }
  .recharts-responsive-container {
    width: auto;
  }
  .main-simpleline-chart {
    display: block !important;
  }
  .piechart-parent {
    display: block !important;
  }
  .placement-bottom-start {
    left: 0px !important;
    top: 80px !important;
    width: fit-content;
  }

  .rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-menu.placement-bottom-start {
    width: 98%;
    overflow-x: auto;
  }
  .css-heipqt-MuiResponsiveChart-container {
    width: auto !important;
  }

  .Dashboard-wrapper {
    height: auto !important;
  }
  .piechart-parent .customer-data {
    width: auto !important;
  }
  .Dashboard-wrapper .piechart-parent {
    display: block !important;
    margin-bottom: 20px !important;
  }
  .piechart-parent .piechart-div {
    margin-top: 38px;
  }

  .piechart-parent .piechart-div {
    width: auto !important;
  }

  .toggle-margin {
    margin-left: 190px;
  }
  .Dashboard-wrapper {
    padding: 0;
  }
  .date-picker-wrap {
    display: none;
  }
  .dashbard-inner-card {
    margin-top: 0;
  }
  .dashbard-inner-card .card-inner {
    margin-bottom: 1rem;
  }
  aside.left-sidebar.active {
    left: 0%;
    display: flex;
    margin-bottom: 50px;
    height: 100%;
  }
  .top-bar {
    display: block;
  }
  .Dashboard-wrapper header {
    height: 105px;
  }

  .sales-register-create.w-75 {
    width: 100% !important;
  }
}
.owlimg {
  text-align: center !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.css-3su884-MuiPaper-root {
  padding: 0px !important;
  margin-bottom: 0px !important;
}

.piechart-parent .recharts-responsive-container {
  height: 551.333px !important;
}
.piechart-parent .customer-data {
  width: 70%;
}

.piechart-parent .piechart-div {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  height: auto;
  width: 100%;
  padding: 20px;
  border-radius: 13px;
}

.recharts-responsive-container {
  height: 300px !important;
}
.piechart-parent .recharts-responsive-container {
  height: 40vh !important;
}
.cart-wrapper .recharts-wrapper {
  top: -2px;
}
.Dashboard-wrapper .piechart-parent {
  height: 360px;
  width: 96%;
  max-width: 100%;
}
i.fas.fa-angle-right {
  color: 6e46c9 !important;
}
i.fas.fa-angle-left {
  color: #6e46c9 !important;
}

button.customer-data-btn-download {
  border: none;
  background-color: #d9d9d9;
  font-size: 8px;
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: 600;
}

.title-customerdata-exportbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}
.color-pilot-name-visitor {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 14px;
}
.color-pilot-name-visitor .dot {
  height: 12px;
  width: 12px;
  background-color: #65129a;
  border-radius: 50%;
  display: inline-block;
}
.color-pilot-name-customer {
  display: flex;
  justify-content: end;
  align-items: center;
}

.color-pilot-name-customer .dot {
  height: 12px;
  width: 12px;
  background-color: #125f9a;
  border-radius: 50%;
  display: inline-block;
}

div#cart-header-three-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-simpleline-chart {
  display: flex !important;
}
.parent-div-progressbar {
  position: relative;
}
.progressbar-wrapper {
  width: 60vw;
  height: 18rem;
}
.parent-color-pillet-perform {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

.pie-chart-wrapper {
  width: auto;
  height: 25rem;
  background-size: cover;
  padding: 20px;
  border: 2px solid #5585d6;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 15px;
  margin: 20px;
}
.fifty-width {
  display: flex;
}
button.customer-data-btn-download {
  margin-right: 10px;
}
.chart-inner-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .dashboard-charts-wrapper {
    padding-bottom: 22px;
  }

  .css-yedi9u {
    width: 100% !important;
    height: 400px;
  }
  form#sales-login-width {
    width: fit-content;
  }
  form#customer-rgister {
    width: auto;
    margin: 50px;
  }
  .aside.left-sidebar.active .sidebar-bottom {
    padding-bottom: 50px !important;
  }
  .sidebar-bottom {
    text-align: center;
    margin-bottom: 24px;
  }
  .parent-div-progressbar {
    height: 350px;
  }

  .color-pilot-name-customerPC,
  .color-pilot-name-customerP {
    text-align: center;
  }

  .customer-login,
  .sales-login {
    height: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chart-inner-text > div {
    margin-top: 1rem;
    margin-left: 0 !important;
    border-color: transparent !important;
  }
}

.css-1vuxth3-MuiBarElement-root {
  stroke: none;
  shape-rendering: crispEdges;
  fill: #4ba3dd;
}

.MuiChartsLegend-mark {
  background-color: #4ba3dd !important;
  height: 12px;
  width: 12px;
  border-radius: 50% !important;
  display: inline-block;
}

.MuiChartsAxis-tickContainer {
  padding-left: 10px !important;
  z-index: 9 !important;
  position: relative;
}

.left-sidebar .toggle-icon-dashboard {
  position: absolute;
  right: 8px;
  top: 3px;
  font-size: 2rem;
  z-index: 9;
}
.table-inner-content.table-responsive.z1919 {
  height: 250px;
}

select#visit_type {
  background-color: #fff !important;
  margin-top: 0px !important;
}

.icons-style-sidebar {
  color: #4ba3dd;
}
tspan text {
  font-size: 13.5px;
  color: #a6a6a6 !important;
  font-weight: 600;
}
g.MuiChartsLegend-series {
  display: none !important;
}
.pie-chart-wrapper #pie-chart-mobile {
  border-top: 1px solid #ccc !important;
  padding: 20px;
  width: 100%;
}
.recharts-responsive-container {
  padding-left: 0px !important;
}
.btn-logout-admindashboard {
  background-color: #fff;
  color: #000;
  font-weight: 600;
  padding: 2px 10px;
  font-size: 0.9rem;
  margin-left: 7px;
  border-radius: 0.5rem;
}

.passwordwithShow {
  display: flex;
  justify-content: center;
  align-items: baseline;
  width: 100%;

  border-radius: 33px;
}
.password-input-wrapper {
  display: flex;
  justify-content: center;
  align-items: baseline;
  width: 100%;
}
.checkboxText {
  margin-left: 10px;
}

.bell {
  position: relative;
  width: 2rem;
  padding: 0;
}

.not-counter {
  position: absolute;
  top: -0.4rem;
  right: -0.4rem;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: bold;
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.2);
}
