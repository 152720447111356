.bg-nav-landing{
  background-color: #FCF7FE !important;
}

.top-section-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  position: relative;
  overflow: hidden;
}

.top-img {
  width: 100vw;
  height: 40rem;
  object-fit: cover;
}

.top-img-mobile {
  width: 100%;
  display: none;
}

.top-banner-content{
  position: absolute;
  bottom: 40%;
  left: 3rem;
  width: 40%;
  font-size: 2.5rem;
}
.top-banner-content > span {
  font-weight: 700;
}


/* Why start */
.why-wiseish-container {
  display: flex;
  align-items: center;
  justify-content: end;
  border-radius: 2rem;
  padding: 0.5rem;
  width: 92%;
  margin: 4rem 3rem;
  position: relative;
  border: 2px solid #c37fff;
  font-size: 1.1rem;
}

.why-circle {
  background-color: #ffffff;
  position: absolute;
  left: -1.7rem;
  border-radius: 50%;
  padding: 20px;
  width: 15.5rem;
  height: 15.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 800;
  font-size: 2.6rem;
  box-shadow: 0 0 10px rgba(139, 56, 211, 0.7);
}

.why-content {
  color: #000000;
  width: 75%;
  padding: 0.5rem;
  margin: 0.2rem;
}

.why-content p {
  margin: 5px 0;
}
/* why complete */
/* key feature start */
.key-features-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.1rem;
  text-align: center;
  margin: 2rem 0;
}

.features-card {
  border: 1px solid #7b17b5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30%;
  height: 17rem;
  margin: 1rem;
  padding: 2rem;
  border-radius: 2rem;
  font-weight: 600;
  transition: transform 0.3s;
}
.features-card:hover {
  transform: translateY(-0.5rem);
}
.features-card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.features-card-img {
  width: 9rem;
  height: auto;
  margin: 0.3rem;
}
.features-card-content {
  text-align: center;
  padding: 1rem 0 0 0;
}
/* key end */
/* feature start */
.main-features-container {
  text-align: center;
  padding: 2rem;
}

.main-feature-card-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  justify-content: center;
  padding: 2rem;
}

.main-feature-card {
  border: 1px solid #7b17b5;
  border-radius: 1.4rem;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s;
  width: 70%;
  min-width: 15rem;
  margin: 0 auto;
}

.main-feature-card:hover {
  transform: translateY(-0.5rem);
}

.main-feature-card-img {
  width: 14rem;
  height: auto;
  padding: 1rem;
}

.main-feature-card-content {
  margin: 0.5rem;
  font-size: 1rem;
  color: #333;
  text-align: center;
}
.main-feature-footer {
  font-size: 1.3rem;
  font-weight: 700;
}
/* feature end */
/* contact start */
.contact-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  flex-direction: column;
  text-align: center;
}
.contact-form-container > h2, .main-features-container > h2 {
  font-size: 2rem;
  font-weight: 800;
}
.contact-form > input,
.contact-form > textarea {
  width: 40rem;
  border-radius: 1rem;
  border: none;
  background-color: #9e91f1;
  padding: 1rem;
  color: #ffffff;
}
.contact-form > textarea::placeholder,
.contact-form > input::placeholder {
  color: #ffffff;
}
.contact-form-container > #contact-btn {
  margin: 1rem;
  padding: 0.7rem;
  border-radius: 0.8rem;
  background-color: rgba(40, 54, 158, 0.929);
  color: #ffffff;
  border: none;
}
@media (max-width: 990px) {
  .top-banner-content{
    font-size: 1.8rem;
  }
  .why-wiseish-container {
    font-size: 0.9rem;
  }
  .why-circle {
    width: 12rem;
    height: 12rem;
    font-size: 2rem;
  }
  .why-content {
    width: 80%;
  }
}

.footer-landing {
  background-color: #9e91f1b3;
  color: #33304b;
  font-size: 0.9rem;
}

.footer-landing a {
  color: #33304b;
  text-decoration: none;
}

.footer-landing a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .top-section-container {
    height: auto;
  }
  .top-img {
    display: none;
  }

  .top-img-mobile {
    display: block;
  }

  .top-banner-content {
    font-size: 1.5rem;
    width: 100%;
  }

  .top-banner-content {
    top: 5%;
    left: 1rem;
}
  .why-wiseish-container {
    margin: 5rem 1rem;
    width: auto;
    justify-content: center;
    font-size: 1rem;
  }
  .why-circle {
    left: auto;
    top: -4rem;
    width: 8rem;
    height: 8rem;
    font-size: 1.2rem;
  }
  .why-content {
    padding: 0.5rem;
    text-align: center;
    margin-top: 4rem;
  }
  .features-card-wrapper {
    flex-direction: column;
  }
  .features-card {
    width: 50vw;
    min-width: 20rem;
  }
  .main-feature-card {
    min-width: 10rem;
    font-size: 0.8rem;
  }
  .main-feature-card-content {
    font-size: 0.8rem;
  }
  .main-features-container,
  .main-feature-card-wrapper {
    padding: 1rem;
  }
  .main-features-container > h4 {
    font-size: 1.2rem;
  }
  .main-feature-card-img {
    width: 9rem;
  }
  .main-feature-footer {
    font-size: 1.1rem;
  }
  .contact-form > input,
  .contact-form > textarea {
    width: auto;
    min-width: 20rem;
  }
  .contact-form-container > button{
    font-size: 1rem;
  }
}
