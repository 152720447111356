.sales-register-create {
  position: static !important;
  margin: auto;
  transform: inherit;
  margin-top: 50px !important;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
}

.table thead th:first-child {
  border-top-left-radius: 10px;
}

.table thead th:last-child {
  border-top-right-radius: 10px;
}
.table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.table-data-wrapper {
  border-radius: 25px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 5px 20px;
  height: 400px;
  overflow-y: auto;
  border: 2px solid #5585D6;
}
.table-inner-content .table-responsive {
  box-shadow: 0 7px 29px 0 hsla(240, 5%, 41%, 0.2);
}
#res-table-layout-admin{
  width: 95%;
  min-width: 95%;
  padding: 0;
  font-size: 0.9rem;
  border: 1px solid #DEE2E6;
}
button.button-30-add-sales-admin {
  background-color: #5585D6;
  padding: 10px;
  color: #fff;
  font-size: 13px;
  margin-bottom: 10px;
  border: none;
  padding: 5px 10px;
}

.buttom-parent-div-sales {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

#sales-password {
  position: relative;
}
.sales-login-icon{
  position: absolute;
  right: 1rem;
  bottom: 37%;
}

@media (max-width: 767px) {
  .sales-register-create {
    margin: 1.3rem;
  }
  .table-data-wrapper {
    height: auto;
    border: 2px solid #3498ff17;
    margin: 0.5rem;
  }
  .sales-register-create input {
    background-color: #3498ff17;
  }
  .sales-register-create h5 {
    margin-bottom: 1rem;
  }
}
