.search-number {
  color: #000000;
  /* width: 25rem; */
  display: flex;
  margin: 1rem 0rem 1rem 0rem;
  align-items: center;
  position: relative;
}
.search-number > input {
  margin: 0 !important;
  width: 5rem;
  height: 2.2rem;
  border: none;
  border-radius: 0.5rem;
  transition: width 0.3s ease-in-out;
  background-color: #dee2e6;
}
.search-number > input:focus {
  width: 10.7rem;
}
.filter-wrapper {
  display: flex;
}
.table-heading-wrapper {
  position: relative;
}
.options-field {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  font-size: 0.8rem;
}
.options-field input {
  background-color: #dee2e6;
}
#customer-options-btn {
  position: absolute;
  right: 1rem;
  top: 0;
  background-color: #dee2e6;
}
.customer-calendar {
  width: 16rem;
  height: 2.4rem;
  /* margin-left: 5rem; */
}
.customer-calendar input {
  margin: 0.1rem !important;
}
#customer-data-heading {
  text-align: left;
  padding-left: 10px;
  margin: 1rem 1.2rem 0.4rem 1.2rem;
  font-weight: bold;
  font-size: 1.6rem;
}
#notification-dropdown-toggle{
  font-size: 0.4rem;
}
#notification-dropdown-toggle::after {
  display: none !important;
}

/* Add this CSS to your stylesheet */

.seen-notify {
  background-color: #f0f0f0; /* Light grey background */
  color: #888888; /* Grey text color */
}

.notification-content {
  display: flex;
  flex-direction: column;
}

.notification-description {
  font-weight: bold;
}

.notification-datetime {
  color: #555;
  font-size: 0.85rem;
}

.notification-customer {
  color: #333;
  font-size: 0.9rem;
}


@media (max-width: 600px) {
  #customer-data-heading {
    font-size: 1.3rem;
    padding-left: 0;
    margin: 0.8rem;
  }
  #customer-data-heading > button {
    margin-left: 0.2rem;
  }
  .excel-btn {
    right: 0.2rem;
  }
  .filter-btn-customer {
    right: 3rem;
  }
  .search-number {
    width: auto;
  }
  .search-number > input {
    width: 11rem;
  }
  .options-field {
    flex-wrap: wrap-reverse;
  }
}
